import React from 'react';

export const PageNotFound = (props) => {

  return (
    <div className= "realBody">
      <h1> PAGE NOT FOUND</h1>
    </div>
  )
}
